<template>
  <div style="margin:0px">
    <el-form
      :model="newObjectDetail"
      :rules="rules"
      ref="form"
      label-width="auto"
      @submit.native.prevent
    >
      <el-row>
        <el-col>
          <h1 class="titleLine primaryColor">
            {{ $t("studentDetail.requiredInformation") }}
          </h1>
        </el-col>
      </el-row>
      <!-- *****School***** -->
      <el-form-item
        :label="$t('studentDetail.school')"
        prop="high_school"
        style="padding-top: 20px"
        required
      >
        <el-input
          :placeholder="$t('studentDetail.school')"
          v-model="newObjectDetail.high_school"
          @focus="colorChangeSchool = true"
          @blur="colorChangeSchool = false"
        >
          <div
            slot="prefix"
            class="input-icon icon-domain"
            :style="
              colorChangeSchool
                ? `background-color: ${themeColor};`
                : 'background-color: #757575;'
            "
          ></div>
        </el-input>
      </el-form-item>

      <!-- *****Grade level***** -->
      <el-form-item
        :label="$t('studentDetail.gradeLevel')"
        style="padding-top: 10px"
        required
      >
        <el-select
          v-model="graduateSelected"
          :placeholder="$t('studentDetail.gradeLevel')"
          style="width:100%"
          @focus="colorChangeGrade = true"
          @blur="colorChangeGrade = false"
        >
          <div
            slot="prefix"
            class="input-icon icon-school"
            :style="
              colorChangeGrade
                ? `background-color: ${themeColor};`
                : 'background-color: #757575;'
            "
          ></div>
          <el-option
            v-for="(item, index) in graduateSelection"
            :key="index"
            :label="$t(item.label, { year: item.param })"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      

      <el-row style="padding-top:20px">
        <el-col>
          <h1 class="titleLine primaryColor">
            {{ $t("studentDetail.optionalInformation") }}
          </h1>
        </el-col>
      </el-row>
      <br />
      <!-- *****Phone***** -->
      <el-form-item
        :label="$t('studentDetail.phone')"
        prop="phone"
        style="padding-top: 10px"
      >
        <el-input
          :placeholder="$t('studentDetail.phone')"
          v-model="newObjectDetail.phone"
          @focus="colorChangePhone = true"
          @blur="colorChangePhone = false"
        >
          <div
            slot="prefix"
            class="input-icon icon-phone"
            :style="
              colorChangePhone
                ? `background-color: ${themeColor};`
                : 'background-color: #757575;'
            "
          ></div>
        </el-input>
      </el-form-item>
      <!-- *****Address***** -->
      <el-form-item
        :label="$t('studentDetail.address')"
        style="padding-top: 10px"
      >
        <el-input
          :placeholder="$t('studentDetail.address')"
          v-model="newObjectDetail.living"
          @focus="colorChangeAddress = true"
          @blur="colorChangeAddress = false"
        >
          <div
            slot="prefix"
            class="input-icon icon-home"
            :style="
              colorChangeAddress
                ? `background-color: ${themeColor};`
                : 'background-color: #757575;'
            "
          ></div>
        </el-input>
      </el-form-item>

      <!-- *****Facebook***** -->
      <el-form-item label="Facebook" style="padding-top: 10px">
        <el-input
          placeholder="Facebook"
          v-model="newObjectDetail.facebook_id"
          @focus="colorChangeFacebook = true"
          @blur="colorChangeFacebook = false"
        >
          <div
            slot="prefix"
            class="input-icon icon-facebook"
            :style="
              colorChangeFacebook
                ? `background-color: ${themeColor};`
                : 'background-color: #757575;'
            "
          ></div>
        </el-input>
      </el-form-item>

      <!-- *****Line***** -->
      <el-form-item label="Line" style="padding-top: 10px">
        <el-input
          placeholder="Line"
          v-model="newObjectDetail.line_id"
          @focus="colorChangeLine = true"
          @blur="colorChangeLine = false"
        >
          <div
            slot="prefix"
            class="input-icon icon-line"
            :style="
              colorChangeLine
                ? `background-color: ${themeColor};`
                : 'background-color: #757575;'
            "
          ></div>
        </el-input>
      </el-form-item>

      <!-- *****Wechat***** -->
      <el-form-item label="Wechat" style="padding-top: 10px">
        <el-input
          placeholder="Wechat"
          v-model="newObjectDetail.wechat_id"
          @focus="colorChangeWechat = true"
          @blur="colorChangeWechat = false"
        >
          <div
            slot="prefix"
            class="input-icon icon-wechat"
            :style="
              colorChangeWechat
                ? `background-color: ${themeColor};`
                : 'background-color: #757575;'
            "
          ></div>
        </el-input>
      </el-form-item>

      <!-- *****Skype***** -->
      <el-form-item label="Skype" style="padding-top: 10px">
        <el-input
          placeholder="Skype"
          v-model="newObjectDetail.skype_id"
          @focus="colorChangeSkype = true"
          @blur="colorChangeSkype = false"
        >
          <div
            slot="prefix"
            class="input-icon icon-skype"
            :style="
              colorChangeSkype
                ? `background-color: ${themeColor};`
                : 'background-color: #757575;'
            "
          ></div>
        </el-input>
      </el-form-item>

      <el-row>
        <el-col>
          <div align="center" justify="center">
            <el-button
              type="primary"
              style="width: 50%"
              @click="submitForm('form')"
              >{{ $t("studentDetail.submit") }}</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import formMixin from "@/mixins/form";
import gradeLevelMixin from "@/mixins/gradeLevel";
import moment from "moment";
export default {
  name: "StudentDetail",
  components: {},
  mixins: [formMixin, gradeLevelMixin],
  props: {
    profile: Object
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      const phoneReg = /^\d+$/;
      if (!value) {
        return callback(new Error(this.$t("studentDetail.phoneRequiredError")));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error(this.$t("studentDetail.phoneNumberError")));
        } else {
          callback();
        }
      }, 100);
    };

    return {
      newObjectDetail: this.createObject(),
      graduateSelection: [],
      graduateSelected: "",
      colorChangeSchool: false,
      colorChangeGrade: false,
      colorChangePhone: false,
      colorChangeAddress: false,
      colorChangeFacebook: false,
      colorChangeLine: false,
      colorChangeWechat: false,
      colorChangeSkype: false,
      testsList: [],
      // from testHelper (mixins)
      tests: [],

      rules: {
        high_school: [
          {
            required: true,
            message: this.$t("studentDetail.schoolRequiredError")
          }
        ]
        // phone: [
        //   {
        //     required: true,
        //     message: this.$t("studentDetail.phoneRequiredError")
        //   },
        //   { validator: checkPhone, trigger: "blur" }
        // ]
      }
    };
  },
  created() {
    // clean up the event object
    this.newObjectDetail = this.createObject();
    this.graduateSelection = this.createStudentGraduateSelection();
    const month = moment()
      .format()
      .substring(5, 7);

    if (parseInt(month) < 7)
      this.graduateSelected = this.graduateSelection[6].value;
    else this.graduateSelected = this.graduateSelection[5].value;
  },
  methods: {
    createObject() {
      const id = this.profile.id;
      const username = this.profile.username;
      const email = this.profile.email;
      const first_name = this.profile.first_name;
      const last_name = this.profile.last_name;
      return {
        user_id: id,
        username: username,
        email: email,
        first_name: first_name,
        last_name: last_name,
        high_school: "",
        high_school_graduation_year: "",
        phone: "",

        scores: {
          psat: [
            {
              reading_score: "",
              grammar_score: "",
              math_score: "",
              taken_on: "",
              is_real_test: 1
            }
          ],
          sat: [
            {
              reading_score: "",
              grammar_score: "",
              math_score: "",
              writing_score: "",
              taken_on: "",
              is_real_test: 1
            }
          ],
          act: [
            {
              english_score: "",
              math_score: "",
              science_score: "",
              reading_score: "",
              writing_score: "",
              taken_on: "",
              is_real_test: 1
            }
          ],
          toefl: [
            {
              reading_score: "",
              listening_score: "",
              speaking_score: "",
              writing_score: "",
              taken_on: "",
              is_real_test: 1
            }
          ],
          ielts: [
            {
              reading_score: "",
              listening_score: "",
              speaking_score: "",
              writing_score: "",
              taken_on: "",
              is_real_test: 1
            }
          ]
        },

        living: "",
        facebook_id: "",
        line_id: "",
        wechat_id: "",
        skype_id: ""
      };
    },

    submitForm(formName) {
      // assign data to high_school_graduation_year
      this.newObjectDetail.high_school_graduation_year = this.graduateSelected;
      // assign data to tests
      this.tests.forEach(obj => {
        switch (obj.name) {
          case "psat":
            this.newObjectDetail.scores.psat[0].reading_score =
              obj.reading_score;
            this.newObjectDetail.scores.psat[0].grammar_score =
              obj.grammar_score;
            this.newObjectDetail.scores.psat[0].math_score = obj.math_score;
            this.newObjectDetail.scores.psat[0].taken_on = obj.taken_on;
            this.newObjectDetail.scores.psat[0].is_real_test = obj.is_real_test;
            break;
          case "sat":
            this.newObjectDetail.scores.sat[0].reading_score =
              obj.reading_score;
            this.newObjectDetail.scores.sat[0].grammar_score =
              obj.grammar_score;
            this.newObjectDetail.scores.sat[0].math_score = obj.math_score;
            this.newObjectDetail.scores.sat[0].writing_score =
              obj.writing_score;
            this.newObjectDetail.scores.sat[0].taken_on = obj.taken_on;
            this.newObjectDetail.scores.sat[0].is_real_test = obj.is_real_test;
            break;
          case "act":
            this.newObjectDetail.scores.act[0].english_score =
              obj.english_score;
            this.newObjectDetail.scores.act[0].math_score = obj.math_score;
            this.newObjectDetail.scores.act[0].science_score =
              obj.science_score;
            this.newObjectDetail.scores.act[0].reading_score =
              obj.reading_score;
            this.newObjectDetail.scores.act[0].writing_score =
              obj.writing_score;
            this.newObjectDetail.scores.act[0].taken_on = obj.taken_on;
            this.newObjectDetail.scores.act[0].is_real_test = obj.is_real_test;
            break;
          case "toefl":
            this.newObjectDetail.scores.toefl[0].reading_score =
              obj.reading_score;
            this.newObjectDetail.scores.toefl[0].listening_score =
              obj.listening_score;
            this.newObjectDetail.scores.toefl[0].speaking_score =
              obj.speaking_score;
            this.newObjectDetail.scores.toefl[0].writing_score =
              obj.writing_score;
            this.newObjectDetail.scores.toefl[0].taken_on = obj.taken_on;
            this.newObjectDetail.scores.toefl[0].is_real_test =
              obj.is_real_test;
            break;
          case "ielts":
            this.newObjectDetail.scores.ielts[0].reading_score =
              obj.reading_score;
            this.newObjectDetail.scores.ielts[0].listening_score =
              obj.listening_score;
            this.newObjectDetail.scores.ielts[0].speaking_score =
              obj.speaking_score;
            this.newObjectDetail.scores.ielts[0].writing_score =
              obj.writing_score;
            this.newObjectDetail.scores.ielts[0].taken_on = obj.taken_on;
            this.newObjectDetail.scores.ielts[0].is_real_test =
              obj.is_real_test;
            break;
        }
      });

      this.$refs[formName].validate(async valid => {
        const firstNameOfLastNameIsEmpty = (
          (this.newObjectDetail.first_name || "").trim() === "" ||
          (this.newObjectDetail.last_name || "").trim() === ""
        );
        const isError = document.getElementsByClassName("is-error");
        if (
          valid &&
          !this.isDuplicate &&
          isError.length === 0 &&
          !firstNameOfLastNameIsEmpty
        ) {
          await this.$store.dispatch(
            "registerDetails/createNewStudent",
            this.newObjectDetail
          );

          if (!this.isSubmitError) {
            this.$message({
              message: this.$t("message.studentBindingSuccess"),
              type: "success"
            });
            this.$router.push({ name: "LandingPage" });
          }
        } else {
          this.$message.error("Oop, something went wrong. Please contact us.");
          return false;
        }
      });
    }
  },
  computed: {
    ...mapState("registerDetails", ["isSubmitError"]),
    ...mapState("user", ["isDuplicate"])
  },
  watch: {
    profile: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.newObjectDetail.username = newData.username;
        this.newObjectDetail.email = newData.email;
        this.newObjectDetail.first_name = newData.first_name;
        this.newObjectDetail.last_name = newData.last_name;
      }
    }
  }
};
</script>

<style scoped>
.titleLine {
  text-align: center;
  font-size: 30px;
}
.primaryColor {
  color: var(--themeColor);
}
.input-icon {
  width: 22px;
  height: 22px;
  margin-top: 7px;
  background-size: cover;
}
.icon-domain {
  mask: url("../../assets/svg/domain.svg") no-repeat;
}
.icon-school {
  mask: url("../../assets/svg/school.svg") no-repeat;
}
.icon-phone {
  mask: url("../../assets/svg/phone.svg") no-repeat;
}
.icon-home {
  mask: url("../../assets/svg/home.svg") no-repeat;
}
.icon-facebook {
  mask: url("../../assets/svg/facebook.svg") no-repeat;
}
.icon-line {
  mask: url("../../assets/svg/line-brands.svg") no-repeat;
}
.icon-wechat {
  mask: url("../../assets/svg/wechat.svg") no-repeat;
}
.icon-skype {
  mask: url("../../assets/svg/skype.svg") no-repeat;
}
</style>
