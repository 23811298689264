import moment from "moment";

export default {
  methods: {
    createStudentGraduateSelection() {
      const time = moment().format();
      const year = time.substring(0, 5);
      const month = time.substring(5, 7);
      let options = [];
      if (!month) return options;
      if (parseInt(month) < 7) {
        let curYear = parseInt(year);
        for (let i = 1; i > 0; i--) {
          let item = {};
          item.label = "studentGradeLevel.graduated";
          item.param = curYear - i;
          item.value = (curYear - i).toString();
          options.push(item);
        }
        for (let i = 0, curGrade = 17; i < 17; i++, curGrade--) {
          let item = {};
          item.label = "studentGradeLevel.grade" + curGrade.toString();
          item.param = curYear + i - 3;
          item.value = (curYear + i - 3).toString();
          options.push(item);
        }
      } else {
        let curYear = parseInt(year);
        for (let i = 1; i > 0; i--) {
          let item = {};
          item.label = "studentGradeLevel.graduated";
          item.param = curYear - i - 4;
          item.value = (curYear - i - 4).toString();
          options.push(item);
        }
        for (let i = 0, curGrade = 17; i < 17; i++, curGrade--) {
          let item = {};
          item.label = "studentGradeLevel.grade" + curGrade.toString();
          item.param = curYear + i - 4;
          item.value = (curYear + i - 4).toString();
          options.push(item);
        }
      }
      return options;
    },
    createTeacherGraduateSelection() {
      const time = moment().format();
      const year = time.substring(0, 5);
      const month = time.substring(5, 7);
      let options = [];

      if (parseInt(month) < 7) {
        let curYear = parseInt(year);
        for (let i = 10; i > 0; i--) {
          let item = {};
          item.label = "teacherGradeLevel.graduated";
          item.param = curYear - i;
          item.value = (curYear - i).toString();
          options.push(item);
        }
        let i = 0;
        for (; i < 4; i++) {
          let item = {};
          item.label = "teacherGradeLevel.grade" + (4 - i).toString();
          item.param = curYear + i;
          item.value = (curYear + i).toString();
          options.push(item);
        }
        let item = {};
        item.label = "teacherGradeLevel.grade" + (4 - i).toString();
        item.param = curYear + i;
        item.value = (curYear + i).toString();
        options.push(item);
      } else {
        let curYear = parseInt(year);
        for (let i = 10; i > 0; i--) {
          let item = {};
          item.label = "teacherGradeLevel.graduated";
          item.param = curYear - i + 1;
          item.value = (curYear - i + 1).toString();
          options.push(item);
        }
        let i = 0;
        for (; i < 4; i++) {
          let item = {};
          item.label = "teacherGradeLevel.grade" + (4 - i).toString();
          item.param = curYear + i + 1;
          item.value = (curYear + i + 1).toString();

          options.push(item);
        }
        let item = {};
        item.label = "teacherGradeLevel.grade" + (4 - i).toString();
        item.param = curYear + i + 1;
        item.value = (curYear + i + 1).toString();
        options.push(item);
      }
      return options;
    }
  }
};
