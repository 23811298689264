<template>
  <el-container style="margin-top: 20px">
    <el-card class="box-card">
      <div>
        <div class="titleStyle">
          <h1 class="primaryColor" style="font-size: 30px;">
            Hello! {{ profile.username }}
          </h1>
          <el-divider></el-divider>
        </div>
        <div style="margin:0">
          <el-form
            :model="profile"
            :rules="rules"
            ref="profile"
            label-width="auto"
          >
            <el-form-item
              :label="$t('registerDetail.username')"
              prop="username"
              :error="usernameError"
            >
              <el-input
                :placeholder="$t('registerDetail.username')"
                v-model="profile.username"
                @focus="colorChangeUsername = true"
                @input="colorChangeIsValid = false"
                @blur="
                  colorChangeUsername = false;
                  checkUsername();
                "
              >
                <div
                  slot="prefix"
                  class="input-icon icon-account"
                  :style="
                    colorChangeUsername
                      ? `background-color: ${themeColor};`
                      : 'background-color: #757575;'
                  "
                ></div>
                <div
                  slot="suffix"
                  class="input-icon icon-check"
                  :style="
                    colorChangeIsValid
                      ? 'background-color: #4CAF50;'
                      : 'background-color: white;'
                  "
                ></div>
              </el-input>
            </el-form-item>

            <el-form-item :label="$t('registerDetail.email')" prop="email">
              <el-input
                :placeholder="$t('registerDetail.email')"
                v-model="profile.email"
                :disabled="true"
              >
                <div
                  slot="prefix"
                  class="input-icon icon-email"
                  :style="
                    colorChangeEmail
                      ? `background-color: ${themeColor};`
                      : 'background-color: #757575;'
                  "
                ></div>
              </el-input>
            </el-form-item>

            <el-row :gutter="20">
              <el-col :md="12">
                <el-form-item
                  :label="$t('registerDetail.firstName')"
                  prop="first_name"
                >
                  <el-input
                    :placeholder="$t('registerDetail.firstNamePlaceholder')"
                    v-model="profile.first_name"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12">
                <el-form-item
                  :label="$t('registerDetail.lastName')"
                  prop="last_name"
                >
                  <el-input
                    :placeholder="$t('registerDetail.lastNamePlaceholder')"
                    v-model="profile.last_name"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <br />
          </el-form>
          <br />
          <StudentDetail :profile="profile" />
        </div>
      </div>
    </el-card>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import JwtService from "@/common/jwt.service";
import StudentDetail from "@/components/roleBinding/StudentDetail";
import formMixin from "@/mixins/form";
import ApiService from "@/common/api.service";

export default {
  components: {
    StudentDetail
  },
  mixins: [formMixin],
  async mounted() {
    // 在 RegisterDetail 頁面檢查如果已經 Binding 完成，則導回 Home 頁面
    const isBinding = await ApiService.post("finish-bind");

    if (isBinding.finishBind) {
      this.$router.push({
        name: "LandingPage"
      });
    }

    await this.$store.dispatch("user/getProfile");
    this.checkUsername();
  },
  data() {
    return {
      type: "student",
      colorChangeUsername: false,
      colorChangeEmail: false,
      colorChangeIsValid: false,
      types: [
        { lebel: "registerDetail.student", value: "student" },
        { lebel: "registerDetail.teacher", value: "teacher" },
        { lebel: "registerDetail.parent", value: "parent" }
      ],
      usernameError: null,
      tokenInput: { Authorization: `Bearer ${JwtService.getToken()}` }
    };
  },
  methods: {
    async checkUsername() {
      const username = this.profile.username;
      if (username) {
        await this.$store.dispatch("user/checkUsername", {
          username: username
        });

        if (this.isDuplicate) {
          // give a random value to usernameError and use $nextTick to avoid the problem that
          // the same error will not appear at bottom of input
          this.usernameError = Math.random().toString();
          this.$nextTick(() => {
            this.usernameError = this.$t(
              "registerDetail.usernameDuplicatedError"
            );
          });
          this.colorChangeIsValid = false;
        } else {
          this.usernameError = null;
          this.colorChangeIsValid = true;
        }
      }
    }
  },
  computed: {
    ...mapState("user", ["profile", "isDuplicate", "token"]),
    ...mapState("registerDetails", ["isBinding"]),
    rules() {
      return {
        first_name: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        username: [
          {
            required: true,
            message: this.$t("registerDetail.usernameRequiredError")
          }
        ]
      };
    }
  },
  watch: {}
};
</script>

<style scoped>
.titleLine {
  text-align: center;
}
.titleStyle {
  display: block;
  overflow: auto;
}
.primaryColor {
  color: var(--themeColor);
}
.select {
  font-size: 30px;
}
.textField {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.box-card {
  display: inline-block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.input-icon {
  width: 22px;
  height: 22px;
  margin-top: 7px;
  background-size: cover;
}
.icon-account {
  mask: url("../assets/svg/account.svg") no-repeat;
}
.icon-email {
  mask: url("../assets/svg/email.svg") no-repeat;
}
.icon-check {
  mask: url("../assets/svg/check-circle.svg") no-repeat;
}
</style>
