var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{staticStyle:{"margin-top":"20px"}},[_c('el-card',{staticClass:"box-card"},[_c('div',[_c('div',{staticClass:"titleStyle"},[_c('h1',{staticClass:"primaryColor",staticStyle:{"font-size":"30px"}},[_vm._v(" Hello! "+_vm._s(_vm.profile.username)+" ")]),_c('el-divider')],1),_c('div',{staticStyle:{"margin":"0"}},[_c('el-form',{ref:"profile",attrs:{"model":_vm.profile,"rules":_vm.rules,"label-width":"auto"}},[_c('el-form-item',{attrs:{"label":_vm.$t('registerDetail.username'),"prop":"username","error":_vm.usernameError}},[_c('el-input',{attrs:{"placeholder":_vm.$t('registerDetail.username')},on:{"focus":function($event){_vm.colorChangeUsername = true},"input":function($event){_vm.colorChangeIsValid = false},"blur":function($event){_vm.colorChangeUsername = false;
                _vm.checkUsername();}},model:{value:(_vm.profile.username),callback:function ($$v) {_vm.$set(_vm.profile, "username", $$v)},expression:"profile.username"}},[_c('div',{staticClass:"input-icon icon-account",style:(_vm.colorChangeUsername
                    ? ("background-color: " + _vm.themeColor + ";")
                    : 'background-color: #757575;'),attrs:{"slot":"prefix"},slot:"prefix"}),_c('div',{staticClass:"input-icon icon-check",style:(_vm.colorChangeIsValid
                    ? 'background-color: #4CAF50;'
                    : 'background-color: white;'),attrs:{"slot":"suffix"},slot:"suffix"})])],1),_c('el-form-item',{attrs:{"label":_vm.$t('registerDetail.email'),"prop":"email"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('registerDetail.email'),"disabled":true},model:{value:(_vm.profile.email),callback:function ($$v) {_vm.$set(_vm.profile, "email", $$v)},expression:"profile.email"}},[_c('div',{staticClass:"input-icon icon-email",style:(_vm.colorChangeEmail
                    ? ("background-color: " + _vm.themeColor + ";")
                    : 'background-color: #757575;'),attrs:{"slot":"prefix"},slot:"prefix"})])],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('registerDetail.firstName'),"prop":"first_name"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('registerDetail.firstNamePlaceholder')},model:{value:(_vm.profile.first_name),callback:function ($$v) {_vm.$set(_vm.profile, "first_name", $$v)},expression:"profile.first_name"}})],1)],1),_c('el-col',{attrs:{"md":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('registerDetail.lastName'),"prop":"last_name"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('registerDetail.lastNamePlaceholder')},model:{value:(_vm.profile.last_name),callback:function ($$v) {_vm.$set(_vm.profile, "last_name", $$v)},expression:"profile.last_name"}})],1)],1)],1),_c('br')],1),_c('br'),_c('StudentDetail',{attrs:{"profile":_vm.profile}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }